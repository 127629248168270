<template>
<section class="w-full py-4">
    <div class="container mx-auto px-8">
        <div class="flex items-center py-8 flex-col lg:flex-row">
            <div class="flex-1 p-8">
                <img src="@/assets/img/comm.svg" alt />
            </div>
            <div class="flex-1 p-8">
                <h3 class="font-body font-semibold text-2xl lg:text-4xl" v-html="$t('community.title')"></h3>
                <p class="leading-loose" v-html="$t('community.text')"></p>
            </div>
        </div>
        <div class="flex items-center py-8 flex-col lg:flex-row-reverse">
            <div class="flex-1 p-8">
                <img src="@/assets/img/art.svg" alt />
            </div>
            <div class="flex-1 p-8">
                <h3 class="font-body font-semibold text-2xl lg:text-4xl" v-html="$t('culture.title')"></h3>
                <p class="leading-loose" v-html="$t('culture.text')"></p>
            </div>
        </div>
        <div class="flex items-center py-8 flex-col lg:flex-row">
            <div class="flex-1 p-8">
                <img src="@/assets/img/edu.svg" alt />
            </div>
            <div class="flex-1 p-8">
                <h3 class="font-body font-semibold text-2xl lg:text-4xl" v-html="$t('education.title')"></h3>
                <p class="leading-loose" v-html="$t('education.text')"></p>
            </div>
        </div>
        <div class="flex items-center py-8 flex-col lg:flex-row-reverse">
            <div class="flex-1 p-8">
                <img src="@/assets/img/sport.svg" alt />
            </div>
            <div class="flex-1 p-8">
                <h3 class="font-body font-semibold text-2xl lg:text-4xl" v-html="$t('sport.title')"></h3>
                <p class="leading-loose" v-html="$t('sport.text')"></p>
            </div>
        </div>
        <div class="flex items-center py-8 flex-col lg:flex-row">
            <div class="flex-1 p-8">
                <img src="@/assets/img/eco.svg" alt />
            </div>
            <div class="flex-1 p-8">
                <h3 class="font-body font-semibold text-2xl lg:text-4xl" v-html="$t('ecology.title')"></h3>
                <p class="leading-loose" v-html="$t('ecology.text')"></p>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: "Action",
};
</script>
